import fetch from 'node-fetch';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

if (!process.browser) {
  const cms = process.env.CMS || 'cms.jayco-com-au.staticlylocal.com';
  var graphQLServer = `https://${cms}/graphql`;
}else{
  var graphQLServer = `https://${process.env.STATICLY_BRANCH_DOMAIN_CMS}/graphql`;
}



// if (process.browser) {
//   console.log('process.env.CMS', process.env.CMS);
//   console.log('process.env.STATICLY_BRANCH_DOMAIN_CMS', process.env.STATICLY_BRANCH_DOMAIN_CMS);
// }

const cache = new InMemoryCache();

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const link = new HttpLink({
  uri: graphQLServer,
  fetch: fetch,
});
const client = new ApolloClient({
  // Provide required constructor fields
  cache: cache,
  link: link,
  defaultOptions
});

export default client;