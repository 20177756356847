import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { theme } from '../../../themes/default-theme';
import { useMediaQuery } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import Link from '../NextLink';
import Text from '../../typography';
import FooterItem from './FooterItem';
import FooterLogo from '../../../assets/icons/new-footer-logo.svg'
import FooterSocial from './FooterSocial';
import { Cta } from '../../ui';
import dlPush from '../../../util/dlPush';

const Wrapper = styled.div`
    background: #2E3238;
`
const Container = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    ${theme.breakpoints.up('md')} {
        padding: 0 40px;
    }
`

const FooterTopContainer = styled.div`
    a {
        text-decoration: none;
    }
    ${theme.breakpoints.up('md')} {
        max-width: 1400px;
        margin: auto;
        padding: 58px 0 50px;
        display: flex;
        gap: 21px;
        justify-content: space-between;
        > div {
            width: calc((100% - 21px)/6)
        }
    }
    ${theme.breakpoints.down('sm')} {
        padding: 36px 0 67px;
    }
`
const FooterBottomContainer = styled.div`
    a {
        text-decoration: none;
    }
    ${theme.breakpoints.up('md')} {
        max-width: 1400px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        > * {
            font-weight: 400;
            font-size: 14px;
            line-height: 30px;
            text-align: center;
            color: #FFFFFF;
            text-decoration: none;
            position: relative;
            &:not(:last-child) {
                margin-right: 15px;
                padding-right: 15px;
                &:after {
                    content: '';
                    position: absolute;
                    background: #fff;
                    height: 15px;
                    width: 1px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    right: 0;  
                }
            }
        }
    }
    ${theme.breakpoints.down('sm')} {
        padding-bottom: 36px;
        > * {
            font-weight: 400;
            font-size: 12px;
            line-height: 30px;
            text-align: center;
            color: #FFFFFF;
            display: block;
        }
    }
`

const FooterCol = styled.div`
    ${theme.breakpoints.up('md')} {
        h3 {
            font-weight: 600;
            font-size: 17px;
            line-height: 21px;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 17px;
        }
        &:last-child {
            min-width: 150px;
        }
    }
    ${theme.breakpoints.up('lg')} {
        &:last-child {
            max-width: 200px;
        }
    }
    ${theme.breakpoints.down('sm')} {
        h3 {
            margin-bottom: 13px;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #FFFFFF;
        }
        > * {
            text-align: center;
            margin-left: left;
            margin-right: auto;
        }
    }
`

const NewsletterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    ${theme.breakpoints.up('md')} {
        margin-bottom: 35px;
        max-widtH: 200px;
        a {
            font-weight: 700;
            font-size: 17px;
            line-height: 23px;
            text-align: center;
            color: #fff;
        }
    }
    ${theme.breakpoints.down('sm')} {
        margin: 0 auto 30px;
        max-widtH: 184px;
        a {
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
        }
    }
`

const FooterLogoContainer = styled.div`
    ${theme.breakpoints.up('md')} {
        margin-bottom: 27px;
    }

    ${theme.breakpoints.down('sm')} {
        margin-bottom: 30px;
    }
`
const LogoSvg = styled(ReactSVG)`
    ${theme.breakpoints.up('md')} {
        text-align: center;
    }
    ${theme.breakpoints.down('sm')} {
        &, svg {
            margin: auto;
            width: 111px;
            height: 66px;
        }
    }
`

const FooterNav = (props) => {
    const [dealerComponentData, setDealerComponentData] = useState(false);

    const {
        assignedDomain,
        countryCode,
        primaryNav,
        route,
        secondaryNav
    } = props;
    console.log(props)
    // Media Breakpoint Start
    const desktopView = useMediaQuery(theme.breakpoints.up('md'));
    const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
    // Media Breakpoint End

    const title = props.countryCode.toLowerCase() === 'nz' ? 'Jayco New Zealand' : 'Jayco Australia';
    const dealerTitle = process.browser && process.env.DEALER_SITE ? window.staticly.dealerData.name : "{{data.template.dealer.name}}"

    const handleNewsletterClick = () => {
        dlPush({
            'event': 'jaycoEvent',
            'eventAction': 'CTA Click - Newsletter Sign-up',
            'eventCategory': 'Research',
            'eventLabel': 'Footer CTA'
        })
    }
    useEffect(() => {

        try {
          const component = document.getElementById('staticly-dynamics-component-FooterItem');
          // const properties = component ? component.getAttribute('data-component-properties') : null;
          // const jsonData  = properties ? JSON.parse(properties) : null;
          const jsonData = window.staticly.data && window.staticly.data.ExploreNav ? window.staticly.data.ExploreNav : null
          
          if (jsonData) {
            // Refactor here
            const data = jsonData.data;
    
            if (!data) {
              component && component.remove();
              return
            }
    
            const arr = Object.values(data).filter(item => item.menuItems.length !== 0).map(item => {
              return {
                ...item,
                assignedDomains: ['jayco.com.au', 'jayco.co.nz', 'AU-dealers', 'NZ-dealers'],
              }
            });
    
            const newData = {
                subItems: arr.map((item, index) => {
                    return {
                        
                            link: {
                                ...item,
                                title: item.name,
                                classes: [item.classes],
                                href: item.slug
                            },
                            children: item.menuItems && item.menuItems.items && item.menuItems.items.map(childItem => {
                                return {
                                    link: {
                                        ...childItem.item,
                                        assignedDomains: ['jayco.com.au', 'jayco.co.nz', 'AU-dealers', 'NZ-dealers'],
                                        classes: []
                                    }
                                }
                            })
                        
                    }
                })
            }
            
            // onDealerData({items: newData});
            // setExploreData({items: newData});
            // setSubNav({items: newData});
            setDealerComponentData(newData);
            component && component.remove();
          }
        } catch {
          // do nothing
        }
    }, [])

    console.log('dealerComponentData', dealerComponentData);

    const footerItemString = `{{#staticlyDynamic data from="template.menus" to="data" id="FooterItem" component="src/components/navigation/NewFooterNav/FooterNav.js"}}{{/staticlyDynamic}}`

    return (
        <Wrapper>
            <Container>
                {primaryNav && primaryNav.length > 0 &&
                    <FooterTopContainer>
                        {desktopView &&
                            <>
                                {primaryNav.map((item, index) => {
                                    return (
                                        item && item.subItems && item.subItems.length > 0 &&
                                            <FooterItem key={index} {...props} item={item} />
                                        
                                    )
                                })}
                                {(process.env.DEALER_SITE) &&
                                    <>
                                        {!dealerComponentData ? 
                                            <div dangerouslySetInnerHTML={{__html: footerItemString}} />
                                        :
                                            <FooterItem  {...props} item={dealerComponentData} />
                                        }
                                    </>    
                                }
                            </>
                        }
                        <FooterCol>
                            <FooterLogoContainer>
                                <Link href={'/'}>
                                    <a aria-label={'Go to home page'}>
                                        <LogoSvg src={FooterLogo} />
                                    </a>
                                </Link>
                            </FooterLogoContainer>
                            <Text variant="h3">Jayco E-Newsletter</Text>
                            <NewsletterContainer
                            >
                                <Cta href={'/enews-registration'} primary uppercase onClick={handleNewsletterClick}>
                                    Sign Up
                                </Cta>
                            </NewsletterContainer>
                            <FooterSocial assignedDomain={assignedDomain} />
                        </FooterCol>

                    </FooterTopContainer>
                }
                {secondaryNav && secondaryNav.length > 0 &&
                    <FooterBottomContainer>
                        {secondaryNav.map((item, index) => {
                            return (
                                <Link key={item.link.id ? item.link.id : index} href={item.link.href && item.link.href} target={item.link.target && item.link.target}>
                                    <a>
                                        {item.link.title}
                                    </a>
                                </Link>
                            )
                        })
                        }
                        <span>
                            &copy; {process.env.DEALER_SITE ? dealerTitle : title}
                        </span>
                    </FooterBottomContainer>
                }
            </Container>
        </Wrapper>
    )
}

FooterNav.propTypes = {

}

FooterNav.defaultProps = {
}

export default FooterNav