import {get} from 'lodash';

const refactorFooterDataV2 = (props) => {
  let primaryNav = [];
  let secondaryNav = [];

  // Footer Primary Nav
  const colItems = get(props, 'footerPrimary.items[0].item.menuItems.items', null);

  if (!colItems) {
    return
  }

  for (let parentItem of colItems) {
    // skip parent menu without title
    if (!parentItem.title) {
      continue;
    }
    // get children items
    let child1Items = [];
    if (get(parentItem, 'childItems.items', false)) {
      for (let child1 of parentItem.childItems.items) {
        let child2Items = [];
        if (get(child1, 'childItems.items', false)) {
          for (let child2 of child1.childItems.items) {
            if (child2.title) {
              child2Items.push({
                link: {
                  title: child2.title,
                  href: child2.href,
                  target: child2.target ? child2.target : '_self',
                  assignedDomains: child2.assignedDomains
                }
              });
            }
          }
        }
        if (child1.title) {
          child1Items.push({
            link: {
              title: child1.title,
              href: child1.href,
              target: child1.target ? child1.target : '_self',
              assignedDomains: child1.assignedDomains,
            },
            children: child2Items
          });
        }
      }
    }
    // Add parent menu items
    primaryNav.push({
      subItems: child1Items
    });
  }

  // Footer Secondary Nav
  const secItems = get(props, 'footerSecondary.items[0].item.menuItems.items', null);

  if (!secItems) {
    return
  }

  for (let parentItem of secItems) {
    // skip parent menu without title
    if (!parentItem.title) {
      continue;
    }
    secondaryNav.push({
      link: {
        title: parentItem.title,
        href: parentItem.href,
        target: parentItem.target ? parentItem.target : '_self',
        assignedDomains: parentItem.assignedDomains,
      },
    });
  }

  return {primaryNav, secondaryNav};
}

export default refactorFooterDataV2;