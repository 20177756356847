const respImgQuery = `
  altText
  sourceUrl
  mediaDetails {
    sizes {
      name
      mimeType
      sourceUrl
      height
      width
    }
    width
    height
  }
  mediaType
  id
`

export default respImgQuery