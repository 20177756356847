import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '../../../themes/default-theme';
import RespImg from '../../ui/RespImg';
import Text from '../../typography';
import Link from '../NextLink';
import { useMediaQuery } from '@material-ui/core';
import HtmlParser from 'react-html-parser';

const SeriesCardInnerContainer = styled.div`
    text-align: center;
    color: red;
    a {
        text-decoration: none;
    }
    ${theme.breakpoints.up('md')} {
        padding: 9px 24px 9px 23px;
        margin-bottom: 10px;
        margin-top: 0;
        &, picture, img {
            max-width: 275px;
        }
    }
    ${theme.breakpoints.down('sm')} {
        
        ${props => props.mobileStyles && `
            
            a {
                display: flex;
                flex-direction: row-reverse;
                width: 100%;
                align-items: center;
                gap: 10px;
            }
        `}

        ${props => (props.hasBorder && props.mobileStyles) && `
            ${props.mobilePadding ? `
                padding: 30px 0;
            ` : `
                padding: 2px 0;
            `}
            border-bottom: 1px solid #D6D6D6;
        `}
    }
    
`
const SeriesCardImage = styled.div`
    ${theme.breakpoints.up('md')} {
        margin-bottom: 5px;
    }
    ${theme.breakpoints.down('sm')} {
        width: 100%;
   }
`
const SeriesCardContent = styled.div`
    h4 {
        color: #0170D4;
        text-transform: uppercase;
        margin-bottom: 5px;
        margin-top: 5px;
    }

    ${props => (props.mobileStyles) && ` 
        > * {
            text-align: center;
        }
    `}

    ${props => (props.hasBorder && props.mobileStyles) && `
        > * {
            text-align: left;
        }
    `}

    ${theme.breakpoints.up('md')} {
        > * {
            text-align: center;
        }
        h4 {
            font-weight: 800;
            font-size: 24px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 1px;
        }
    }
    ${theme.breakpoints.down('sm')} {
        width: 100%;
        h4 {
            font-weight: 800;
            font-size: 18px;
            line-height: 24px;
        }
    }
`
const SeriesCardContentBottom = styled.div`
    display: flex;
    > * {
        font-weight: 500;
        font-size: 12px;
        line-height: 21px;
        color: #758094;
        &:first-child {
            position: relative;
            text-transform: capitalize;
        }
    }
    ${props => (props.mobileStyles && !props.hasBorder) && `
        justify-content: center;
    `}
    ${theme.breakpoints.up('md')} {
        justify-content: center;
    }
    ${props => props.showSleeps && `
        > * {
            &:first-child {
                margin-right: 8px;
                padding-right: 8px;
                &:after {
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: 12px;
                    background: #758094;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    `}
`

const SeriesCard = (props) => {

    const {
        title,
        href,
        subTitle,
        sleeps,
        image,
        hasBorder,
        refactorURL,
        mobileStyles,
        hideSleeps,
        mobilePadding,
        active
    } = props;

    // Media Breakpoint Start
    const mediaUp = useMediaQuery(theme.breakpoints.up('md'));
    const mediaDown = useMediaQuery(theme.breakpoints.down('sm'));
    // Media Breakpoint End


    return (
        <SeriesCardInnerContainer hasBorder={hasBorder} mobileStyles={mobileStyles} mobilePadding={mobilePadding}>
            <Link href={href ? refactorURL(href) : '#'}>
                <a>
                    <SeriesCardImage>
                        {(image) &&
                            <RespImg
                                {...image}
                                type="png"
                                visible={active}
                            />
                        }
                    </SeriesCardImage>
                    <SeriesCardContent mobileStyles={mobileStyles} hasBorder={hasBorder}>
                        {title &&
                            <Text variant="h4" data-survey-equalize>{HtmlParser(title.toUpperCase().replace('POP TOP', 'POP&nbsp;TOP'))}</Text>
                        }
                        <SeriesCardContentBottom mobileStyles={mobileStyles} hasBorder={hasBorder} showSleeps={hideSleeps ? 0 : 1}>
                            {subTitle &&
                                <Text variant="body2">{subTitle}</Text>
                            }
                            {sleeps && !hideSleeps &&
                                <Text variant="body2">{`${mediaUp ? 'Sleeps' : 'Sleeps up to'} ${sleeps}`}</Text>
                            }
                        </SeriesCardContentBottom>
                    </SeriesCardContent>
                </a>
            </Link>
        </SeriesCardInnerContainer>
    )
}

SeriesCard.propTypes = {
    title: propTypes.string,
    href: propTypes.string,
    subTitle: propTypes.string,
    sleeps: propTypes.any,
    image: propTypes.object,
    hasBorder: propTypes.bool,
    refactorURL: propTypes.func,
    mobileStyles: propTypes.bool,
    hideSleeps: propTypes.bool,
    mobilePadding: propTypes.bool,
    active: propTypes.bool
}

SeriesCard.defaultProps = {
    title: null,
    href: null,
    subTitle: null,
    sleeps: null,
    image: {},
    hasBorder: null,
    refactorURL: (url) => { return url },
    mobileStyles: true,
    hideSleeps: false,
    mobilePadding: true,
    active: false
}

export default SeriesCard