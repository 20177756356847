// Header Nav query goes here
import respImgFragment from '../resp-img';

const headerNavQuery = (slug) => {
    return `
      menus(where: {slug: "${slug}"}) {
        items: nodes {
          menuItems(first: 1000) {
            items: nodes {
              href: url
              assignedDomains
              title: label
              target
              id
              classes: cssClasses
              acf_main_menu_fields {
                mmifImage {
                  ${respImgFragment}
                }
              }
              childItems {
                items: nodes {
                  id
                  assignedDomains
                  href: url
                  title: label
                  target
                  classes: cssClasses
                  childItems {
                    items: nodes {
                      id
                      assignedDomains
                      title: label
                      href: url
                      target
                      classes: cssClasses
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  }
  
  export default headerNavQuery