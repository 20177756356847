import React, {useState, useEffect} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Button} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {theme} from '../../../themes/default-theme';
import NewSearchIcon from '../../../assets/icons/new_search_icon.svg';

const Wrapper = styled.div `
  text-align: center;
`

const Container = styled.div`
  position: relative;
  display: block;

  ${theme.breakpoints.down('md')} {
  }
`

const Input = styled(TextField)`
  width: 100%;
  
  input {
    padding-left: 19px;
    padding-right: 60px;
    background-color: white;
    border-radius: 55px;
    line-height: 15px;

    ::-webkit-input-placeholder { line-height: normal; }


    ${theme.breakpoints.down('sm')} {
      padding: 10px 45px 10px 14px;
    }
  }

  fieldset {
    border-radius: 55px;
  }
  
`

const Submit = styled(Button)`
  width: 35px;
  height: 35px;
  border-radius: 30px;
  min-width: auto;
  color: ${theme.palette.white};
  position: absolute;
  right: 11px;
  top: 50%;
  transform: translateY(-50%);

  ${theme.breakpoints.down('sm')} {
    width: 26px;
    height: 26px;
    right: 10px;
  }
`

const SubmitIcon = styled.img`
  width: 18px;
  height: 18px;
  display: block;

  ${theme.breakpoints.down('sm')} {
    width: 17px;
    height: 17px;
  }
`
  
const NewNavSearch = ({
  onFormSubmit,
  placeholder,
  autoFocus
}) => {

  const ref = React.createRef();

  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(false);

  const handleChange = event => {
    setSearchTerm(event.target.value);
    if (event.target.value.trim().length > 0) {
      setError(false)
    }
  };

  const handleSubmit = () => {
    if (searchTerm.trim().length > 0) {
      onFormSubmit(searchTerm.trim())
    } else {
      setError(true)
    }
  }

  const handleKeyDown = () => {
    event.key.toLowerCase() === 'enter' && handleSubmit();
  }

  useEffect(() => {
    autoFocus && ref.current.focus();
  })

  return (
    <Wrapper>
      <Container>
        <Input
          placeholder={placeholder}
          value={searchTerm}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          variant="outlined"
          type="search"
          inputRef={ref}
          error={error}
        />
        <Submit onClick={handleSubmit}>
          <SubmitIcon src={NewSearchIcon} alt="search"/>
        </Submit>
      </Container>
    </Wrapper>
  )
}
  
NewNavSearch.propTypes = {
  onFormSubmit: propTypes.func.isRequired,
  placeholder: propTypes.string,
  autoFocus: propTypes.bool
}

NewNavSearch.defaultProps = {
  placeholder: 'Search',
  autoFocus: true
}
  
export default NewNavSearch