import {get, isEmpty} from 'lodash';
import refactorRespImg from '../../ui/resp-img';

const refactorHeaderNavV2 = (props) => {
  let rangeNav = {};
  let nav = [];
  let explore = {};
  let stock = {};
  
  // Range Nav
  if (get(props, 'rangeNav.rangeListingsPage.rangeRef', false)) {
    rangeNav.ranges = [];
    let rangeNavItems = props.rangeNav.rangeListingsPage.rangeRef;
    rangeNavItems.forEach((item, index) => {
      let rangeUri = '/range/' + item.uri;
      let rangeTitle = item.title;
      let series = [];
      
      if (get(item, 'range.seriesRef', false)) {
        item.range.seriesRef.forEach((item2, index2) => {
          let seriesItem = item2.acf_series;
          series.push({
            title: item2.title,
            href: rangeUri + '/' + item2.uri,
            subTitle: rangeTitle,
            sleeps: seriesItem.sleepingMax,
            image: seriesItem.seriesImage ? refactorRespImg(seriesItem.seriesImage, [{
              size: 'default',
              key: 'image_size_300x200'
            },{
              size: 'md',
              key: 'image_size_400x264',
            }]) : null
          });
        });
        rangeNav.ranges.push({
          title: rangeTitle,
          series
        });
      }
    });
    // hardcoded view all
    rangeNav.viewAll = {
      'title':'View all',
      'href': '/range',
      'target': '_self',
      'assignedDomains': [
        'jayco.com.au',
        'jayco.co.nz',
        'AU-dealers',
        'NZ-dealers'
      ]
    };
  } 
  // NOTICE ODD ISSUE _app.js GraphQL query returns data restructure results directly
  else if (get(props, 'rangeNav.ranges', false)) { 
    rangeNav = props.rangeNav;
  }
  
  // Nav
  const navItems = get(props, 'nav.items[0].menuItems.items', []);
  for (let parentItem of navItems) {
    // skip parent menu without title
    if (!parentItem.title) {
      continue;
    }
    // get children items
    let child1Items = [];
    if (get(parentItem, 'childItems.items', false)) {
      for (let child1 of parentItem.childItems.items) {
        let child2Items = [];
        if (get(child1, 'childItems.items', false)) {
          for (let child2 of child1.childItems.items) {
            if (child2.title) {
              child2Items.push({
                title: child2.title,
                href: child2.href,
                target: child2.target ? child2.target : '_self',
                assignedDomains: child2.assignedDomains
              });
            }
          }
        }
        if (child1.title) {
          child1Items.push({
            title: child1.title,
            href: child1.href,
            target: child1.target ? child1.target : '_self',
            assignedDomains: child1.assignedDomains,
            links: child2Items
          });
        }
      }
    }
    // Handle 'Our Range' links
    if (parentItem.title == 'Our Range') {
      rangeNav.links = child1Items;
      continue;
    }
    // Handle 'Explore' links
    if (parentItem.title == 'Explore') {
      explore = {
        title: parentItem.title,
        image: get(parentItem, 'acf_main_menu_fields.mmifImage', false) ? refactorRespImg(parentItem.acf_main_menu_fields.mmifImage, [{
          size: 'default',
          key: 'image_size_275x220'
        }]) : null,
        items: !isEmpty(child1Items) ? child1Items : []
      };
      continue;
    }

    if (parentItem.title.toLowerCase() === 'current stock' || parentItem.title.toLowerCase() === 'stock') {
      stock = {
        title: parentItem.title,
        image: get(parentItem, 'acf_main_menu_fields.mmifImage', false) ? refactorRespImg(parentItem.acf_main_menu_fields.mmifImage, [{
          size: 'default',
          key: 'image_size_275x220'
        }]) : null,
        items: !isEmpty(child1Items) ? child1Items : []
      };
      continue;
    }

    // Add parent menu items
    nav.push({
      title: parentItem.title,
      href: parentItem.href ? parentItem.href : '#',
      image: get(parentItem, 'acf_main_menu_fields.mmifImage', false) ? refactorRespImg(parentItem.acf_main_menu_fields.mmifImage, [{
        size: 'default',
        key: 'image_size_275x220'
      }]) : null,
      items: !isEmpty(child1Items) ? child1Items : [],
      parentItem
    });
  }

  // NOTICE ODD ISSUE _app.js GraphQL query returns data restructure results directly
  if (isEmpty(nav) && !isEmpty(props.nav)) {
    nav = props.nav
  }

  // Update for initialProps for Mobile Nav
  props.rangeNav = rangeNav;
  props.nav = nav;
  props.explore = explore;

  return {
    ...props,
    rangeNav,
    nav,
    explore,
    stock,
    initialProps: props,
  }
}

export default refactorHeaderNavV2