
import React, {useState, useEffect} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {theme} from '../../../themes/default-theme';

const MenuBurger = styled.span`
  width: 21px;
  height: 14px;
  position: relative;
  border-radius: 24px;
  display: inline-block;
  vertical-align: middle;
`

const MenuBurgerLine = styled.i`
  display: block;
  width: 21px;
  height: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #758094;
  left: 0;
  right: 0;
  margin: auto;
  transition: all 300ms ease;

  .small {
    ${theme.breakpoints.down('sm')} {
      width: 18px;
      height: 2px;
      ${props => props.active === 1 && `
        background: transparent;
      `}
    }

    &:before {
      ${theme.breakpoints.down('sm')} {
        width: 18px;
        height: 2px;
        bottom: -5px;
      }
    }

    &:after {
      ${props => props.smallStyling && `
        ${theme.breakpoints.down('sm')} {
          top: -5px;
        }
      `}
    }
  }

  ${props => props.active === 1 && `
    background: transparent;
  `}

  &:after,
  &:before {
    content: ' ';
    width: 21px;
    height: 2px;
    background: ${props => props.active ?  '#758094' : '#758094'};
    display: block;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    transition: all 200ms ease-in-out;
  }

  &:after {
    top: -6px;

    ${props => props.active && `
      transform: rotate(45deg);
      top: 0;
 
      ${theme.breakpoints.down('sm')} {
        top: 0;
      }
    `}
  }

  &:before {
    bottom: -6px;

    ${props => props.active && `
      transform: rotate(-45deg);
      bottom: 0;
      ${theme.breakpoints.down('sm')} {
        bottom: 0;
      }
    `}
  }
`

const NewBurger = (props) => {
  const [active, setActive] = useState(props.active)
  const [smallStyling, setSmallStyling] = useState(props.smallStyling)

  useEffect(() => {
    setActive(props.active)
  }, [props.active])

  useEffect(() => {
    setSmallStyling(props.smallStyling)
  }, [props.smallStyling])

  return (
    <MenuBurger>
      <MenuBurgerLine active={active} className={smallStyling ? 'small' : ''}/>
    </MenuBurger>
  )
}
  
NewBurger.propTypes = {
  active: propTypes.number,
  smallStyling: propTypes.bool
}
  
NewBurger.defaultProps = {
  active: 0,
  smallStyling: true
}
  
export default NewBurger