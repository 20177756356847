// Footer query goes here
import respImgFragment from '../resp-img';

const footerQuery = (slug) => {
    return `
    menus(where: {slug: "${slug}"}) {
        items: edges {
          item: node {
            id
            menuItems(first: 10000) {
              items: nodes {
                href: url
                title: label
                target
                id
                assignedDomains
                classes: cssClasses
                childItems {
                  items: nodes {
                    id
                    href: url
                    title: label
                    target
                    assignedDomains
                    classes: cssClasses
                    childItems {
                      items: nodes {
                        id
                        title: label
                        href: url
                        target
                        assignedDomains
                        classes: cssClasses
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  }
  
  export default footerQuery