import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { theme } from '../../../themes/default-theme';
import { ReactSVG } from 'react-svg';
import Link from '../NextLink';
import Facebook from '../../../assets/icons/new-footer-facebook.svg';
import Instagram from '../../../assets/icons/new-footer-instagram.svg';
import Youtube from '../../../assets/icons/new-footer-youtube.svg';
import Linkedin from '../../../assets/icons/new-footer-linkedin.svg';


const FooterSocialContainer = styled.div`
    display: flex;
    justify-content: center;
    ${theme.breakpoints.up('md')} {
        gap: 14px;
    }
    ${theme.breakpoints.down('sm')} {
        gap: 29px;
    }
`


const Social = styled(ReactSVG)`
    &, svg {
        ${theme.breakpoints.up('md')} {
            width: 20px;
            height: 20px;
        }
        ${theme.breakpoints.down('sm')} {
            width: 18px;
            height: 18px;
        }
    }
`

const FooterSocial = (props) => {


    const [data, setData] = useState(props);
    const [dealerData, setDealerData] = useState(false);

    useEffect(() => {

        try {
            const component = document.getElementById('staticly-dynamics-component-SocialFooterLinks');
            const jsonData = window.staticly.data && window.staticly.data.SocialFooterLinks ? window.staticly.data.SocialFooterLinks : null

            if (component && !jsonData) {
                component.remove();
                return
            }

            if (jsonData) {
                setData(jsonData);
                setDealerData(true);
                component && component.remove();
            }
        } catch {
            // do nothing
        }
    }, []);

    const hbString = `{{#staticlyDynamic data from="template.dealer" id="SocialFooterLinks" component="src/components/navigation/FooterNav/SocialLinks.js"}}{{/staticlyDynamic}}`

    return (
        !process.env.DEALER_SITE ?
            <FooterSocialContainer>
                <Link href={props.assignedDomain === 'jayco.co.nz' ? "https://www.facebook.com/jayconewzealand/" : "https://www.facebook.com/JaycoAust/"}>
                    <a aria-label={'Facebook'} target="_blank">
                        <Social src={Facebook} />
                    </a>
                </Link>
                <Link href={"https://www.instagram.com/jaycoaustralia/"}>
                    <a aria-label={'Instagram'} target="_blank">
                        <Social src={Instagram} />
                    </a>
                </Link>

                <Link href={"https://www.youtube.com/jaycoaustraliaofficial/"}>
                    <a aria-label={'Youtube'} target="_blank">
                        <Social src={Youtube} />
                    </a>
                </Link>
                <Link href={"https://www.linkedin.com/company/jayco-australia/"}>
                    <a aria-label={'Linkedin'} target="_blank">
                        <Social src={Linkedin} />
                    </a>
                </Link>
            </FooterSocialContainer>
            :
            (!dealerData) ?
                <div dangerouslySetInnerHTML={{ __html: hbString }}>
                </div>

                :

                <FooterSocialContainer>
                    {data.facebook &&
                        <Link href={data.facebook}>
                            <a aria-label={'Facebook'} target="_blank">
                                <Social src={Facebook} />
                            </a>
                        </Link>
                    }
                    {data.instagram &&
                        <Link href={data.instagram}>
                            <a aria-label={'Instagram'} target="_blank">
                                <Social src={Instagram} />
                            </a>
                        </Link>
                    }
                    {data.youtube &&
                        <Link href={data.youtube}>
                            <a aria-label={'Youtube'} target="_blank">
                                <Social src={Youtube} />
                            </a>
                        </Link>
                    }
                    {data.linkedin &&
                        <Link href={data.linkedin}>
                            <a aria-label={'Linkedin'} target="_blank">
                                <Social src={Linkedin} />
                            </a>
                        </Link>
                    }
                </FooterSocialContainer>



    )
}

FooterSocial.propTypes = {
}

FooterSocial.defaultProps = {
}

export default FooterSocial

