import {get} from 'lodash';

const refactorRespImg = (img, sizes, oneBackupSize) => {

  const singleBackup = (oneBackupSize !== null || oneBackupSize !== undefined) ? oneBackupSize : true

  if (!img) {
    return null;
  }
  // const original = img.sourceUrl ? [{name: 'original', sourceUrl: img.sourceUrl + ',' +img.sourceUrl.replace('.png', '.webp') + ',' + img.sourceUrl.replace('.png', '.jpg')}] : []
  // const original = img.sourceUrl ? [{name: 'original', sourceUrl: img.sourceUrl.replace('.png', '.webp') + ',' }] : []

  const imgSizesArr = [...get(img, 'mediaDetails.sizes', [])];

  let sizeObj = {};

  imgSizesArr.filter(item => {
    return sizes.some(v => item && item.name && item.name.includes(v.key));
  }).forEach((item, i) => {
    const src = get(item, 'sourceUrl', null);
    const srcArr = src.split(',');
    // const imgTypeIndex = src.lastIndexOf('.');
    // const imgType = src.substring(imgTypeIndex);
    // const webp = src ? src.replace(imgType, '.webp') : null;

    if (srcArr.length === 0) {
      return;
    }

    const key = sizes.find(i => i.key === item.name).size;
    const imgObj = getImageObj(srcArr)

    if (src) {
      sizeObj[key] = {
        width: get(item, 'width', ''),
        height: get(item, 'height', ''),
        png: imgObj.png || null,
        jpg: imgObj.jpg || null,
        webp: imgObj.webp || null,
      }
    }
  })

  const initialImg = imgSizesArr.find(item => {
    return item.name === 'medium'
  });

  const placeholderImages = initialImg && initialImg.sourceUrl ? (initialImg.sourceUrl.indexOf(',') !== -1 ? getImageObj(initialImg.sourceUrl.split(',')) : []) : [];

  const allSizes = imgSizesArr.map(item => {
    const src = get(item, 'sourceUrl', null);
    const srcArr = src.split(',');
    const imgObj = getImageObj(srcArr)
    if (srcArr.length === 0) {
      return;
    }
    if (src) {
      return {
        name: get(item, 'name', null),
        width: get(item, 'width', ''),
        height: get(item, 'height', ''),
        png: imgObj.png || null,
        jpg: imgObj.jpg || null,
        webp: imgObj.webp || null,
      }
    }
  }).filter(item => {
    if (singleBackup || item.name === 'medium') {
      return true
    }
  });

  // const allSizes = {
  //   width: get(backupItem, 'width', ''),
  //   height: get(backupItem, 'height', ''),
  //   src: get(backupItem, 'src', ''),
  // };
  
  const image = {
    alt: img.altText || '',
    sizes: sizeObj,
    allSizes,
    original: img.sourceUrl ? img.sourceUrl.replace('.png', '.webp') : null,
    placeholderSrc: placeholderImages,
  }

  return image

  // Goal:
  // "alt": "id enim cupidatat",
  // "sizes": {
  //   "default": {
  //     "width": "640",
  //     "height": "300",
  //     "src": "//unsplash.it/640/300.jpg?random&gravity=center&rand=87440",
  //     "webp": "//unsplash.it/640/300.webp?random&gravity=center&rand=71178"
  //   },
  //   "md": {
  //     "width": "1200",
  //     "height": "600",
  //     "src": "//unsplash.it/1200/600.jpg?random&gravity=center&rand=13671",
  //     "webp": "//unsplash.it/1200/600.webp?random&gravity=center&rand=72533"
  //   }
  // }
}

const getImageObj = (srcArr) => {
  const webp = srcArr.find(item => item.indexOf('.webp') !== -1);
  const jpg = srcArr.find(item => item.indexOf('.jpg') !== -1);
  const png = srcArr.find(item => item.indexOf('.png') !== -1);

  return {
    webp: webp || null,
    jpg: jpg || null,
    png: png || null
  }
}

export default refactorRespImg