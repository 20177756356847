import React from 'react';
import propTypes from 'prop-types';
import { get } from 'lodash';
import styled from 'styled-components';
import { theme } from '../../../themes/default-theme';
import { useMediaQuery, ButtonBase } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import Link from '../NextLink';
import Text from '../../typography';

const FooterCol = styled.div`

`
const FooterColInner = styled.div`
    ${theme.breakpoints.up('md')} {
        &:not(:first-child) {
            margin-top: 16px;
        }
    }

`
const NavTopLink = styled.div`
    ${theme.breakpoints.up('md')} {
        margin-bottom: 2px;
        a {
            font-weight: 600;
            font-size: 17px;
            line-height: 21px;
            color: #FFFFFF;
        }
    }
`
const NavLinks = styled.div`
    ${theme.breakpoints.up('md')} { 
        a {
            font-weight: 400;
            font-size: 14px;
            line-height: 30px;
            color: #FFFFFF;
        }
    }
`

const FooterItem = (props) => {
    const {
        assignedDomain,
        countryCode,
        item,
        route
    } = props;

    return (
        <FooterCol>
            {item && item.subItems && item.subItems.length > 0 &&
                item.subItems.map((item, index) => {
                    return (
                        <FooterColInner key={item.id ? item.id : index}>
                            <NavTopLink>
                                <Link href={(item.link.href && item.link.href.indexOf('#') !== -1) ? '/' : item.link.href} target={item.link.target && item.link.target}>
                                    <a  onClick={(e) => {
                                            e.preventDefault()
                                        }}>
                                        {item.link.title}
                                    </a>
                                </Link>
                            </NavTopLink>
                            {item.children && item.children.length > 0 &&
                                item.children.map((item, index) => {
                                    return (
                                        <NavLinks key={item.link.id ? item.link.id : index}>
                                            <Link href={item.link.href && item.link.href} target={item.link.target && item.link.target}>
                                                <a>
                                                    {item.link.title}
                                                </a>
                                            </Link>
                                        </NavLinks>
                                    )
                                })

                            }
                        </FooterColInner>
                    )
                })
            }
        </FooterCol>
    )
}

FooterItem.propTypes = {
    assignedDomain: propTypes.string,
    countryCode: propTypes.string,
    item: propTypes.object,
    route: propTypes.string,
    secondaryNav: propTypes.array
}

FooterItem.defaultProps = {
    assignedDomain: null,
    countryCode: 'AU',
    item: {},
    route: null,
    secondaryNav: []
}

export default FooterItem