const removeURLParam = (url, parameter) => {
  //prefer to use l.search if you have a location/link object
  let urlparts = url.split('?');
  var newUrl
  if (urlparts.length >= 2) {
    const prefix = encodeURIComponent(parameter)+'=';
    var pars = urlparts[1].split(/[&;]/g);
    //reverse iteration as may be destructive
    for (var i= pars.length; i-- > 0;) {    
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {  
        pars.splice(i, 1);
      }
    }
    newUrl = urlparts[0] + '?' + pars.join('&');
    if (newUrl.substring(newUrl.length-1) === "?"){
      newUrl = newUrl.substring(0, newUrl.length - 1);
    }
    return newUrl;
  } else {
    return url;
  }
}

export default removeURLParam