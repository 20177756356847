import {findIndex, get} from 'lodash';
import triggerLsEvent from './triggerLsEvent';
import dlPush from './dlPush'

const key = 'savedCaravans';
const maxItems = 3;

const saveItem = (item) => {
	// Get the existing data
	let existing = localStorage.getItem(key);

	// If no existing data, create an array
  if (!existing) {
    localStorage.setItem(key, '[]');
  }
  const lsItems = localStorage.getItem(key);

	// Otherwise, convert the localStorage string to an array

	let items = JSON.parse(lsItems);

  if (get(item, 'data.title', null)) {
    dlPush({
      'event': 'jaycoEvent',
      'eventAction': 'Save to Favorites',
      'eventCategory': 'Research',
      'eventLabel': item.data.title
    })
  }


	// Add new data to localStorage Array
  if (items && findIndex(items, item) === -1) {
    // Save back to localStorage
    if (items.length >= maxItems) {
      items.splice(0, 1);
    }
    localStorage.setItem(key, JSON.stringify(items.concat([item])));
  }

  triggerLsEvent();

}

const removeItem = (item) => {
  const lsItem = localStorage.getItem(key);
  let existing = lsItem ? JSON.parse(lsItem) : null;

  const index = existing && existing.length > 0 ? findIndex(existing, item) : -1;

  existing.splice(index, 1);
  localStorage.setItem(key ,JSON.stringify(existing));
  triggerLsEvent();
}

const getItems = () => {
  if (process.browser) {
    return JSON.parse(localStorage.getItem(key));
  } else {
    return []
  }
}

export {getItems, saveItem, removeItem}