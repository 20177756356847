// Header Range Nav query goes here
import respImgFragment from '../resp-img';

const rangeNavQuery = () => {
  return `
    rangeListingsPageBy(uri: "range") {
      title
      rangeListingsPage:acf_range_listings_page {
        rangeRef:rangeListingsPageRangeRef {
          ... on Range {
            uri
            title
            range:acf_range {
              seriesRef:rangeSeriesRef {
                ... on Series {
                  title,
                  uri
                  acf_series {
                    seriesHeroBanner{
                      seriesHbHeadingText
                    }
                    sleepingMax
                    seriesImage {
                      ${respImgFragment}
                    }
                    seriesSpecsRep {
                      seriesSpecRepTitle
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    `
  }
  
  export default rangeNavQuery