import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import NextLink from '../NextLink';
import {theme} from '../../../themes/default-theme';

import dealer from '../../../assets/icons/dealer.svg';
import brochure from '../../../assets/icons/brochure.svg';
import liveChat from '../../../assets/icons/live-chat.svg';
import enews from '../../../assets/icons/enews.svg';
import phone from '../../../assets/icons/phone-white.svg';
import cart from '../../../assets/icons/cart-white.svg';
import van from '../../../assets/icons/van-white.svg';

const upMd = '@media (min-width: 940px)' // '@media (min-width: 840px)'
const downSm = '@media (max-width: 939.95px)' // '@media (max-width: 839.95px)'

const CtaPanelContainer = styled.div`
  & {
    ${upMd} {
      position: fixed;
      top: 50%;
      transform: translateY(-50%);
      right: -200px;
      transition: all 0.5s ease;
      z-index: 3;
      &:hover {
        right: 0px;
        transition: all 0.5s ease; 
      }
    }
  }

  & .panel:first-child .icon-panel {
    border-top-left-radius: 8px;
  }

  & .panel:last-child {
    border-bottom: none !important;
    .icon-panel {
      border-bottom-left-radius: 8px;
    }
  }
`

const CtaPanelIndividual = styled.div`
  height: 61px;
  width: 260px;
  border-bottom: 1px solid ${theme.palette.middleGrey};
  position: relative;

  ${downSm} {
    height: 41px;
    width: 100%;
    overflow: hidden;
    
    &:first-child {
      border-top-right-radius: 8px;
    }

    &:last-child {
      border-bottom-right-radius: 8px;
    }
  }

  a {
    display: block;
    text-decoration: none;
  }
`

const CtaPanelIcon = styled.div`
  width: 60px;
  height: 60px;
  padding: 14px;
  text-align: center;
  display: block;
  background: ${theme.palette.vividBlue};
  position: absolute;

  ${downSm} {
    width: 40px;
    height: 40px;
    padding: 9px;
  }

  &::after {
    content: '';
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    width: 100%;
    height: 100%;
    display: block;
  }

  &.dealer::after {
    background: url('${dealer}');
  }
  &.brochure::after {
    background: url('${brochure}');
  }
  &.live-chat::after {
    background: url('${liveChat}');
  }
  &.enews::after {
    background: url('${enews}');
  }
  &.phone::after {
    background: url('${phone}');
  }
  &.cart::after {
    background: url('${cart}');
  }
  &.van::After {
    background: url('${van}');
  }
`

const CtaPanelCaption = styled.div`
  
  & {
    height: 60px;
    padding-left: 18px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    line-height: 60px;
    vertical-align: top;
    margin-left: 60px;
    background-color: ${theme.palette.vividBlue};

    ${downSm} {
      line-height: 3;
      margin-left: 40px;
      height: 40px;
      line-height: 40px;
    }
  }
`

export const CtaPanel = props => {
  const {panelData, onItemClick} = props;

  return (
    <CtaPanelContainer>
      {panelData && panelData.map(function(panel, index) {
        return (
          <CtaPanelIndividual key={panel.id} className="panel">
              <CtaPanelIcon
                className={`${panel.icon} icon-panel`}
              />
              <NextLink href={panel.href}>
                <a onClick={() => onItemClick(panel)} target={panel.target ? panel.target : '_self'}>
                  <CtaPanelCaption>
                    {panel.title}
                  </CtaPanelCaption>
                </a>
            </NextLink>
          </CtaPanelIndividual>
        )
      })}
    </CtaPanelContainer>
  );
}

CtaPanel.propTypes = {
  panelData: PropTypes.array,
  onItemClick: PropTypes.func
};

CtaPanel.defaultProps = {
  panelData: [],
  onItemClick: () => {}
}

export default CtaPanel;