import React from 'react';
import FooterNav from './FooterNav';


const NewFooterNav = (props) => {
    console.log(props)
    return (
        <FooterNav
            {...props}
            countryCode={props.countryCode}
        />
    )
}

NewFooterNav.propTypes = {

}

NewFooterNav.defaultProps = {
}

export default NewFooterNav