import {get} from 'lodash';

const filterDealerMotorHomes = (href) => {
  if (href && process.env.DEALER_SITE && process.browser && get(window, 'staticly.dealerData', null)) {
    if (
      get(window, 'staticly.dealerData.motorhomes', '').toString() === 'false' && href.indexOf('/range/jayco-motorhomes') !== -1
      ||
      get(window, 'staticly.dealerData.campervans', '').toString() === 'false' && href.indexOf('/range/jayco-campervans') !== -1
      ||
      get(window, 'staticly.dealerData.motorhomes', '').toString() === 'false' && href.indexOf('/range/motorhomes_campervans') !== -1 // ?
      ||
      get(window, 'staticly.dealerData.campervans', '').toString() === 'false' && href.indexOf('/range/motorhomes_campervans') !== -1 // ?
    ) {
      return false
    }
  } return true
}

const sellsMotorhomes = () => {
  if (process.env.DEALER_SITE && process.browser && get(window, 'staticly.dealerData', null)) {
    if (
      get(window, 'staticly.dealerData.motorhomes', '').toString() === 'false'
      ||
      get(window, 'staticly.dealerData.campervans', '').toString() === 'false'
    ) {
      return false
    }
  }
  return true
}

const sellsCampervans = () => {
  if (process.env.DEALER_SITE && process.browser && get(window, 'staticly.dealerData', null)) {
    if (
      get(window, 'staticly.dealerData.motorhomes', '').toString() === 'false'
      ||
      get(window, 'staticly.dealerData.campervans', '').toString() === 'false'
    ) {
      return false
    }
  }
  return true
}

export {sellsMotorhomes, sellsCampervans, filterDealerMotorHomes as default}